<!-- =========================================================================================
    File Name: ChatNavbar.vue
    Description: Chat Application - Chat navbar
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div v-if="userId != null" class="chat__header">

        <vs-navbar class="p-4 flex navbar-custom" color="white" type="flat">

            <div class="relative flex mr-4">
                <feather-icon icon="MenuIcon" class="mr-4 cursor-pointer" v-if="isSidebarCollapsed" @click.stop="$emit('openContactsSidebar')" />
                <vs-avatar class="m-0 border-2 border-solid border-white" size="40px" :src="userDetails.full_path" @click.stop="$emit('showProfileSidebar', userId)" />


                <div class="h-3 w-3 border-white border border-solid rounded-full absolute right-0 bottom-0" :class="'bg-' + getStatusColor(false)"></div>
            </div>

            <h6>{{ userDetails.username }}</h6>

            <vs-spacer></vs-spacer>

            
            <vs-button @click="DisableRoomStatus" :disabled="!Room.status" >ألغاء التغعيل</vs-button>

        </vs-navbar>

    </div>
</template>

<script>
export default {
  props: {
    userId: {
      required: true
    },
    isPinnedProp: {
      type: Boolean,
      required: true
    },
    isSidebarCollapsed: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isPinnedLocal: {
      get () {
        return this.isPinnedProp
      },
      set (val) {
        const chatData = this.$store.getters['chat/chatDataOfUser'](this.userId)
        if (chatData) {
          const payload = { id: this.userId, value: val }
          this.$store.dispatch('chat/toggleIsPinned', payload)
            .then(() => { this.$emit('toggleIsChatPinned', val) })
            .catch((err) => { })
        } else {
          this.$emit('toggleIsChatPinned', val)
        }
      }
    },
    userDetails () {
      return this.$store.getters['chat/contact'](this.userId).users[0].info
    },
    getStatusColor () {
      return (isActiveUser) => {
        const userStatus = this.getUserStatus()

        if (userStatus) {
          return 'success'
        } else {
          return 'danger'
        }
      }
    },
    Room(){
      return this.$store.getters['chat/contact'](this.userId)

    }
  },
  methods: {
    getUserStatus () {
      return this.userDetails.is_active ? true : false
    },

    async DisableRoomStatus(){

      //call the api to change the room status
      await this.$store.dispatch('chat/UpdateRoomStatus',this.userId)

      //notify the user
      this.$vs.notify({
          title: "تم ألغاء تفعيل الغرفة",
          color:'primary',
          iconPack: 'feather',
          icon:'icon-bell'
        })

    }
  }
}

</script>

<!-- =========================================================================================
    File Name: ChatNavbar.vue
    Description: Chat Application - Chat navbar
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

