<!-- =========================================================================================
    File Name: ChatContact.vue
    Description: Chat contact - single component for chat
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div class="chat__contact flex items-center px-2 pt-4 pb-2" :class="{'bg-primary-gradient text-white shadow-lg': isActiveChatUser}">
        <div class="contact__avatar mr-1 relative inline-flex">
            <vs-avatar badge=true class="border-2 border-solid border-white" :src="contact.users[0].info.full_path" size="42px" />
            <div class="h-4 w-4 border-white border border-solid rounded-full absolute left-1 bottom-2" :class="'bg-' + (contact.status ? 'success': 'danger')"></div>

        </div>
        <div class="contact__container w-full flex items-center justify-between overflow-hidden">
            <div class="contact__info flex flex-col truncate w-5/6">
                <h5 class="font-semibold" :class="{'text-white': isActiveChatUser}" >{{ contact.users[0].info.username }}</h5>
                <span class="truncate">
                     رقم الطلب : {{ contact.id }} 
                </span>
            </div>

            <div class="chat__contact__meta flex self-start flex-col items-end w-1/6">
                <span class="whitespace-no-wrap" style="font-size: 12px;">{{ new Date(Number.parseInt( contact.latestUpdate )).getDate()+'/'+new Date(Number.parseInt( contact.latestUpdate )).getMonth()+1 }}</span>
                <vs-chip class="number" color="primary" v-if="contact.NoNotSeentMsgs">{{ contact.NoNotSeentMsgs }}</vs-chip>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    contact          : { type: Object,  required: true },
    isActiveChatUser : { type: Boolean },
    lastMessaged     : { type: String,  default : '' },
    showLastMsg      : { type: Boolean, default: false },
    unseenMsg        : { type: Number,  default : 0 }
  }
}
</script>

<!-- =========================================================================================
    File Name: ChatContact.vue
    Description: Chat contact - single component for chat
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
